import React, { Component } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  Button,
  FilledInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Icon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Email } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import * as CommonApi from '../../utils/common-api'
import { withRouter } from "react-router";

const styles = (theme) => ({
  root: {
    paddingTop: "20px",
    paddingBottom: "30px",
    flexGrow: 1,
  },

  marginGrid: {
    margin: "30px auto",
  },
  button: {
    padding: "8px 60px",
    marginTop: "10px",
    marginBottom: "50px",
  },
  iconMail: {
    color: "#656565",
    padding: "10px",
  },
  margin: {
    marginTop: theme.spacing(4),
  },
});

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      helperText: "",
    };
    this.handleClickSave = this.handleClickSave.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  handleClickSave = async (event) => {
    if (this.state.email.length === 0) {
      this.setState({ helperText: "description.EmptyMail" });
      return;
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
    ) {
      this.setState({ helperText: "description.NotAMail" });
      return;
    } else {
      this.setState({ helperText: "" });
    }

    const { history } = this.props;
    //Call api login
    try {
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: 'POST',
        body: JSON.stringify({ email: this.state.email })
      };
      var response = await fetch(CommonApi.getBaseUrlApi() + "/forgot-password", requestOptions)
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          this.setState({ helperText: "description.EmailError" });
        } else {
          this.setState({ helperText: "description.GenericError" });
        }
      } else {
        history.push("/sentpassword")
      }

      //this.setState({ token: data.token });
    } catch (error) {
      this.setState({ helperText: "description.GenericError" });
    }


  };

  onKeyPress = (e) => {
    if(e.which === 13) {
      this.handleClickSave();
    }  }

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };





  render() {
    const { classes, t } = this.props;

    return (

      <Grid container className={classes.root} alignContent="center">
        <Grid
          item
          align="center"
          xs={12}
          sm={4}
          className={classes.marginGrid}
        >
          <Typography color="primary" variant="h6">
            <strong>{t(`description.ForgotPassword`)}</strong>
          </Typography>
          <Typography color="inherit" variant="body1">
            {t(`description.SendCredentials`)}
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControl variant="filled">
            <InputLabel htmlFor="Email" className={classes.margin}>
              {" "}
              {t(`description.Email`)}
            </InputLabel>
            <FilledInput
            onKeyPress={this.onKeyPress}
              id="Email"
              type="email"
              value={this.state.email}
              onChange={this.handleChangeEmail}
              className={classes.marginGrid}
              endAdornment={
                <InputAdornment position="end">
                  <Icon className={classes.iconMail}>
                    <Email />
                  </Icon>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Box className={classes.warning}>
            <Typography align="center" color="error">
              {t(`${this.state.helperText}`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleClickSave}
            href="#"
          >
            {t(`description.Send`)}
          </Button>
        </Grid>
        <Grid item xs={12} align="center " className={classes.marginGrid}>
          <Link href="/login" color="primary">
            {t(`description.BackToLogin`)}
          </Link>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(ForgotPasswordForm)));
