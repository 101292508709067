import React from 'react';
import { Grid, ListItemText, Typography, Container, Divider, List, ListItem, IconButton, Drawer } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import Footer from '../footer/Footer'
import { Today, Menu, ArrowRightAlt, Room } from '@material-ui/icons';
import iconStore from '../../images/IconStore.png';
import queryString from 'query-string';
import { withRouter } from "react-router";
import * as CommonStorage from '../../utils/common-storage'


const styles = theme => ({
  restaurantInfo: {
    backgroundColor: "#CB2953",
    paddingBottom: 40,
    paddingTop: 40,
    textAlign: 'center',
  },
  imgItem: {
    marginBottom: '20px',
  },
  list: {
    flexGrow: 1,
  },
  arrowIcon: {
    padding: '0 5px 0 5px',
    color: '#E3E3E3',
    verticalAlign: 'text-bottom',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 18,
    verticalAlign: 'text-bottom',
  },
  todayBar: {
    backgroundColor: '#E3E3E3',
    padding: '20px 15px 15px',
  },
  link: {
    textDecoration: 'none',
  },
  linkSelected: {
    textDecoration: 'none',
    cursor: 'default',
    backgroundColor: '#cb2953',
    '*, *::before, *::after': {
      disableRipple: true,
    },
    '& div': {
      backgroundColor: '#cb2953',
      cursor: 'default',
      '&:hover': {
        backgroundColor: '#cb2953',
      },
    },
    '& p': {
      color: '#fff',
    },
    '&:hover': {
      backgroundColor: '#cb2953',
    },
    '& svg': {
      color: '#cb2953',

    },


  },
});




class ZoneListMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, selectedListZone: false, };
    this.changeZone = this.changeZone.bind(this)
  }

  /*
    state = {
      open: false,
      place_name: "",
      place_address: "",
      zone: []
    }*/

  bottone = () => {
    this.setState({
      open: true
    })
  };

  div = () => {
    this.setState({
      open: false
    })
  };



  componentDidMount() {
    const expand = CommonStorage.getExpandZoneMenu()
    if (expand === "true") {
      this.setState({
        open: true
      })
    }

    CommonStorage.setExpandZoneMenu("false")

  }





  changeZone = (event, zone) => {
    event.preventDefault();

    const { refreshPage } = this.props;

    if(CommonStorage.getCurrentZone().id !== zone.id){
      CommonStorage.setCurrentZone(zone)
      refreshPage()
    }


    this.setState({
      open: false
    })
  }


  render() {
    const { classes, dateNow, Zone, currentPlace } = this.props;
    const currentZone = CommonStorage.getCurrentZone();


    return (

      <div>
        <Drawer anchor='left' open={this.state.open} onClose={this.div} className={classes.root}>
          <Container className={classes.restaurantInfo} >
            <Grid xs={12} container>
              <Grid item xs={12} className={classes.imgItem} >
                <img src={iconStore} alt="Logo" width='60px' />
              </Grid>
              <Grid item xs={12} sm >
                <Typography variant="h2" align="center" color='textSecondary'>
                  <strong>{currentPlace.name}</strong>
                </Typography>
                <Typography variant="body1" align="center" color='inehrit' color='textSecondary'>
                </Typography>
                {currentPlace.description}
              </Grid>
            </Grid>
          </Container>

          <Container className={classes.todayBar} >
            <Typography variant='body1'>
              <Today className={classes.icon} />{dateNow}
            </Typography>
          </Container>

          <List className={classes.list}>
            {Zone.map((row) => (
              /* {row.zonelink} */
              <a onClick={(e) => this.changeZone(e, row)} href="#none" color="primary"
                className={currentZone.id === row.id ? classes.linkSelected : classes.link}>
                <ListItem button color="primary" >
                  <Grid container alignItems='end' justify='space-between' >
                    <Grid item xs={10} justify='space-between' >
                      <Typography color="primary" variant='body1' >
                        <strong>{row.name}</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} alignItems='end' justify='end' >
                      <ArrowRightAlt className={classes.arrowIcon} />
                    </Grid>
                  </Grid>
                  <ListItemText />
                </ListItem>
                <Divider />
              </a>
            ))}


          </List>
          <Footer />
        </Drawer>
        <IconButton edge="start" color="inherit" aria-label="menu"
          aria-controls="simple-menu" aria-haspopup="true" onClick={this.bottone}>
          <Menu />
        </IconButton>
      </div>

    );



  }
}


export default withStyles(styles)(withRouter(ZoneListMenuPage)); 