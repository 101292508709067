import React, { Component } from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import iconStore from '../../images/IconStore.png';



const styles = theme => ({
  root: {
    paddingTop: '90px',
    paddingBottom: '30px',
    backgroundColor: '#CB2953',
    textAlign: 'left',
  },
  imgItem: {
    padding: '0 10px',
    alignSelf: 'center',
  },
});



class StoreInformation extends Component {

  render() {
    const { classes, currentPlace } = this.props;
    return (
      <div className={classes.root}>
        <Container  >
          <Grid item xs={12} sm container>
            <Grid item className={classes.imgItem}>
              <img src={iconStore} alt="Logo" width='60px' />
            </Grid>
            <Grid item xs={12} sm  >
              <Typography variant="h2" align="left" color='textSecondary'>
                <strong>{currentPlace.name}</strong>
              </Typography>
              <Typography variant="body1" align="left" color='inehrit' color='textSecondary'>
                <strong>{currentPlace.description}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>

    )
  }
}




export default withStyles(styles)(StoreInformation);