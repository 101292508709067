import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import i18n from './config/i18n';

//Auto language
var language_raw = window.navigator.userLanguage || window.navigator.language;
var language_parsed = language_raw.substring(0, language_raw.indexOf('-'))
var language = language_parsed.length > 0 ? language_parsed : language_raw;

i18n.changeLanguage(language);


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);


