import React, { Component } from 'react';
import { Grid, Typography, LinearProgress, } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';


const styles = theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '30px',
    flexGrow: 1,
  },
  progressBar: {
    height: 15,
    borderRadius: 15,
    backgroundColor: ' rgb(227, 227, 227)',
  },

  shapeCircle: {
    margin: '30px auto',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    padding: '30px 0',
    width: 90,
    height: 30,
    textAlign: 'center',
  },


});




class RealTimeAffluence extends Component {



  render() {
    const { classes, affluence, peopleMax, percentagePeople, t } = this.props;



    return (

      <Grid xs={12} container className={classes.root}>
        <Grid item xs={12}>
          <Typography color="primary" variant='h6'>
            <strong>{t(`description.NowInStore`)}</strong>
          </Typography>

          <div className={classes.shapeCircle}>
            <Typography color='textSecondary' variant='h6'>
              <strong>{affluence}/{peopleMax}</strong>
            </Typography>
          </div>
          <LinearProgress variant="determinate" value={percentagePeople} className={classes.progressBar} />
        </Grid>
      </Grid>

    )
  }
}




export default withStyles(styles)(withTranslation()(RealTimeAffluence));