import React from 'react';
import { Typography, Toolbar, AppBar, IconButton, Container,Menu,MenuItem,Button   } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import { Settings,  ExitToApp } from '@material-ui/icons';
import ZoneListMenuPage from '../zoneComponents/ZoneListMenuPage';
import { withRouter } from "react-router";
import { Translation, withTranslation } from "react-i18next";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },

});

class MainNavigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl:null,
  
    }
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  
  
  handleClick = (event) => {
   // this.state.setAnchorEl(event.currentTarget);
    this.setState ({anchorEl: event.currentTarget})
  };

   handleClose = () => {
   // this.state.setAnchorEl(null);
    this.setState ({anchorEl: null})
  };

 

  render() {
    const { t, classes, Zone, currentZone, dateNow, currentPlace, refreshPage, showToolbar, showZoneListMenu } = this.props;

   

    return (
      <AppBar position="fixed" color="primary">
        <Container maxWidth="lg">
          {showToolbar === true && (
            <Toolbar>

              {showZoneListMenu === true && (
                <ZoneListMenuPage refreshPage={refreshPage} currentPlace={currentPlace} Zone={Zone} className={classes.menuButton} dateNow={dateNow} />
              )}
              <Typography className={classes.title} color="inherit" variant='h5'>
                <strong>{currentZone.name}</strong>
              </Typography>

            
              <IconButton href="/setting" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <Settings />
                
              </IconButton>
              <IconButton href="/login" edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <ExitToApp />
                
              </IconButton>

            </Toolbar>
          )}
        </Container>
      </AppBar>
    )

  }
}


export default withStyles(styles)(withRouter(withTranslation()(MainNavigation)));