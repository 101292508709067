import React, { Component } from "react";
import { Button, Typography, Container } from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    paddingTop: "30px",
    paddingBottom: "30px",
    backgroundColor: "#E3E3E3",
    textAlign: "center",
  },
  button: {
    padding: "8px 60px",
    marginTop: "40px",
  },
});

class Timelinebutton extends Component {
  render() {
    const { classes, t } = this.props;
    return (

      <Container maxWidth="false" className={classes.root}>
        <Typography variant="body1" align="center">
          <strong>{t(`description.HistoryList`)}</strong>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href="/pdfhistory"
        >
          {t(`description.PdfHistory`)}
        </Button>
      </Container>
    );
  }
}

export default withStyles(styles)(withTranslation()(Timelinebutton));
