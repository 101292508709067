import React, { Component } from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  Button,
  IconButton,
  FilledInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Icon,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Translation } from "react-i18next";
import { withTranslation } from 'react-i18next';
import { VisibilityOff, Visibility, AccountCircle } from "@material-ui/icons";

import * as CommonApi from '../../utils/common-api'
import * as CommonStorage from '../../utils/common-storage'
import { withRouter } from "react-router";
import PropTypes from 'prop-types';




const styles = (theme) => ({
  root: {
    paddingTop: "20px",
    paddingBottom: "30px",
    flexGrow: 1,
  },
  iconUser: {
    color: "#656565",
    padding: "10px",
  },
  marginGrid: {
    margin: "30px auto",
  },
  button: {
    padding: "8px 60px",
    marginTop: "10px",
    marginBottom: "50px",
  },
  margin: {
    marginTop: theme.spacing(4),
  },
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      user: "",
      helperText: "",
    };
    this.handleClickLogin = this.handleClickLogin.bind(this);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }
  handleClickLogin = async (event) => {



    try {

      //Pre Verify fields
      if (this.state.password.length + this.state.user.length === 0) {
        this.setState({ helperText: "description.EmptyPassword" });
        return
      }
      this.setState({ helperText: "" });

      const { i18n, history } = this.props;
      const currentLanguage = i18n.language

      //Call api login
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: 'POST',
        body: JSON.stringify({ username: this.state.user, password: this.state.password, language: currentLanguage })
      };
      var response = await fetch(CommonApi.getBaseUrlApi() + "/login", requestOptions)
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          this.setState({ helperText: "description.CredentialsError" });
        } else {
          this.setState({ helperText: "description.GenericError" });
        }
      } else {
        CommonStorage.setExpandZoneMenu(true)
        history.push("/")
      }

      this.setState({ token: data.token });
    } catch (error) {
      this.setState({ helperText: "description.GenericError" });
    }



  };


  onKeyPress = (e) => {
    if(e.which === 13) {
      this.handleClickLogin();
    }  }

  handleChangePassword = (event) => {
    this.setState({ password: event.target.value });
  };
  handleChangeUser = (event) => {
    this.setState({ user: event.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {
    const { classes, t, i18n } = this.props;
    return (

      <Grid container className={classes.root} alignContent="center">
        <Grid item xs={12} align="center">
          <FormControl variant="filled">
            <InputLabel htmlFor="User" className={classes.margin}>
              {" "}
              {t(`description.User`)}
            </InputLabel>
            <FilledInput
              onKeyPress={this.onKeyPress}
              onChange={this.handleChangeUser}
              value={this.state.user}
              className={classes.marginGrid}
              id="User"
              endAdornment={
                <InputAdornment position="end">
                  <Icon className={classes.iconUser}>
                    <AccountCircle />
                  </Icon>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControl variant="filled">
            <InputLabel htmlFor="Password" className={classes.margin}>
              {" "}
              {t(`description.Password`)}
            </InputLabel>
            <FilledInput
            onKeyPress={this.onKeyPress}
              label={t(`description.RepeatPassword`)}
              id="Password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.password}
              onChange={this.handleChangePassword}
              className={classes.marginGrid}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                        <VisibilityOff />
                      )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid container alignItems="center" justify="center">
          <Box className={classes.warning}>
            <Typography align="center" color="error">
              {t(`${this.state.helperText}`)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.handleClickLogin}
            href="#"
          >
            {t(`description.Login`)}
          </Button>
        </Grid>
        <Grid item xs={12} align="center " className={classes.marginGrid}>
          <Link color="primary" href="/forgotpassword">
            {t(`description.ForgotPassword`)}
          </Link>
        </Grid>
      </Grid>

    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(LoginForm)));
