import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Text } from 'recharts';
import { Today } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

const styles = theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '30px',
    flexGrow: 1,
  },
  overflowstat: {
    padding: '20px 0',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  areaChart: {
    backgroundColor: ' #E3E3E3',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 18,
    verticalAlign: 'text-bottom',
  },
});




class StatsPdf extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.getImage = this.getImage.bind(this);
  }

  getImage() {
    let chartSVG = ReactDOM.findDOMNode(this.myRef.current).children[0];
    var serializedSVG = new XMLSerializer().serializeToString(chartSVG);
    var base64Data = window.btoa(serializedSVG);
    var image = document.createElement('img');
    var canvas = document.createElement('canvas');

    var ctx = canvas.getContext('2d');

    image.src = "data:image/svg+xml;base64," + base64Data

    const { imageChartLoaded } = this.props;
    image.onload = function () {
      canvas.width = image.width
      canvas.height = image.height
      ctx.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL();
      imageChartLoaded(dataURL)
    }
  }

  render() {
    const { classes, listDailyAffluance, dateNow, t } = this.props;
    return (

      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <Typography color="primary" variant='h6'>
            <strong>{t(`description.DailyAffluence`)}</strong>
          </Typography>
        </Grid>
        <Grid item xs={6} alignContent='flex-end'>

          <Typography variant='body1' align="right" display='block'>
            <Today className={classes.icon} />{dateNow}
          </Typography>
        </Grid>

        <ResponsiveContainer height={300} width={960} className={classes.overflowstat} >
          <AreaChart
            ref={this.myRef}
            className={classes.areaChart}
            data={listDailyAffluance}
            margin={{
              top: 10, right: 30, left: 0, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 0" />
            <XAxis dataKey="hours" interval={100} dy={5} />
            <YAxis />
            <Area dataKey="people" stroke="#CB2953" fill="#CB2953" fillOpacity="1" onAnimationEnd={this.getImage} />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>

    )
  }
}



export default withStyles(styles)(withTranslation()(StatsPdf));