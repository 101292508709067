import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    display: "flex",
  },
  peopleCounter: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  nameZonestyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  boxContainer: {
    width: "100%",
    height: "100%",
  },
});



class Welcome extends Component {
  loadFonts(template) {
    const font = new FontFace("fontSelected", `url(${template.font_url})`);
    // add font to document
    document.fonts.add(font);
  }

  render() {
    const { classes, counter, template, regionName } = this.props;

    this.loadFonts(template);
    return (
      <>
        <Box>
          <Box
            style={{
              backgroundImage: `url(${template.image_welcome_counter})`,
            }}
            className={classes.root} >

            <Box className={classes.boxContainer}>
              <Typography
                className={classes.peopleCounter}
                variant="h1"
                style={{
                  fontFamily: "fontSelected",
                  color: template.font_color,
                  top: template.margintop_counter,
                  fontSize: template.font_size,
                }}
              >
                {counter}
              </Typography>

              {template.welcomeCounter && template.welcomeCounter.show_namezone === true &&
                <Typography
                  className={classes.nameZonestyle}
                  variant="h1"
                  style={{
                    fontFamily: "fontSelected",
                    color: template.welcomeCounter.font_color_namezone,
                    top: template.welcomeCounter.margintop_namezone,
                    fontSize: template.welcomeCounter.font_size_namezone,
                  }}
                >
                   {regionName.substring(0,15)} 
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Welcome));
