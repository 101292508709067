import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import LogoHeader from '../components/header/LogoHeader'
import LoginForm from '../components/login/LoginForm';
import CircularLoading from '../components/Progress/CircularLoading';
import * as CommonApi from '../utils/common-api'
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import * as CommonStorage from '../utils/common-storage'

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
  },

}
);


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { screen: 'page' };
  }


  componentDidMount() {
    CommonStorage.clearAll()
  }





  render() {
    return (
      <div>
        <LogoHeader />
        <Container >
          {this.state.screen === 'loading' && (
            <CircularLoading />)}
          {this.state.screen === 'page' && (
            <Grid container spacing={0} alignContent='center'>
              <Grid item xs={12}  >
                <LoginForm />
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(LoginPage));