import React from 'react';
import { Toolbar, AppBar,  Container} from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import logowhite from '../../images/LogoWhite.png';

const styles = theme =>  ({
  root: {
    height:64,
    flexGrow: 0,
  },
});

class Footer extends React.Component {
    render() {
      const { classes } = this.props;
    return (
    <AppBar position="static" color="primary" className={classes.root}>
    <Container maxWidth="lg">
      <Toolbar>
      <img src={logowhite} alt="Logo" width='90px' />
      </Toolbar>
    </Container>
    </AppBar>
    )
  }
}


export default withStyles(styles)(Footer);