import React, { Component } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import iconMailSent from '../../images/MailSent.png';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '30px',
    flexGrow: 1,
  },

  marginGrid: {
    margin: '30px auto'
  },
  button: {
    padding: '8px 60px',
    marginTop: '50px',
  },
  imgMail: {
    maxWidth: '180px',
    margin: '10px auto 30px',
    width: '100%',
  },
});


class SentPassword extends Component {



  render() {
    const { classes, t } = this.props;
    return (

      <Grid xs={12} container className={classes.root} alignContent='center'>
        <Grid item xs={12} sm={4} align='center' className={classes.marginGrid}>
          <img src={iconMailSent} alt="Logo" className={classes.imgMail} />
          <Typography color="primary" variant='h6' >
            <strong> {t(`description.EmailSent`)}</strong>
          </Typography>
          <Typography color="inherit" variant='body1' >
            {t(`description.CredentialsSent`)}
          </Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <Button variant="contained" color="primary" className={classes.button} href='/login' >
            {t(`description.GoToLogin`)}
          </Button>
        </Grid>

      </Grid>

    )
  }
}




export default withStyles(styles)(withTranslation()(SentPassword));