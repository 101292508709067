import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import BackArrowNavigation from '../components/header/BackArrowNavigation'
import CircularLoading from '../components/Progress/CircularLoading';

import PropTypes from "prop-types";
import { withRouter } from "react-router";


const styles = theme => ({
  root: {
    flexGrow: 1,
  },

}
);


class InitPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  render() {
    const { match, location, history } = this.props;
    return (
      <div>
        <div>{location.pathname}</div>
        <CircularLoading />

      </div>
    )
  }
}
const ShowTheLocationWithRouter = withRouter(InitPage);

export default InitPage