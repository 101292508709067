import React, { PureComponent } from "react";

import { withTranslation } from "react-i18next";

import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import fontRoboto from "../fonts/roboto_regular.ttf";
import fontRobotoBold from "../fonts/Roboto-Bold.ttf";
import Closedboard from "../images/stat.png";
import { Button } from "@material-ui/core";

const styles = StyleSheet.create({
  body: {
    paddingTop: 60,
    padding: 30,
  },
  nameZone: {
    fontSize: 36,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#CB2953",
  },
  nameStore: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#000000",
  },
  info: {
    fontSize: 11,
    textAlign: "left",
    fontFamily: "Roboto",
    color: "#6C6F7A",
    paddingTop: 1,
  },

  boxLine: {
    paddingTop: 20,
    marginBottom: 10,
    paddingBottom: 0,
    width: "100%",
    borderBottomColor: "#E0E0E0",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  container: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 20,
    marginBottom: 20,
    paddingBottom: 0,
    width: "100%",
  },
  containerStat: {
    width: "100%",
  },

  leftColumn: {
    flexDirection: "column",
    width: "50%",
  },

  title: {
    float: "left",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#CB2953",
    width: "100%",
  },
  data: {
    float: "right",
    fontSize: 12,
    textAlign: "right",
    fontFamily: "Roboto",
    color: "#6C6F7A",
    paddingTop: 4,
    width: "100%",
  },
  image: {
    height: "auto",
    marginBottom: 10,
    marginHorizontal: 0,
    width: "100%",
  },

  containerTable: {
    alignSelf: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '7px 5px 0 5px',
    maxHeight: '28px',
    minHeight: '28px',
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#E0E0E0",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  firstColumn: {
    flexDirection: "column",
    width: "15%",
  },
  secondColumn: {
    flexDirection: "column",
    width: "55%",
  },
  thirdColumn: {
    flexDirection: "column",
    width: "30%",
    textAlign: "right",
  },
  time: {
    color: '#6C6F7A',
    fontSize: 12,
    fontFamily: "RobotoBold",
    textAlign: "left",
  },
  typeAlarm: {
    color: '#6C6F7A',
    fontSize: 11,
    fontFamily: "Roboto",
    textAlign: "left",
  },
  people: {
    fontSize: 13,
    fontFamily: "RobotoBold",
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    left: 35,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Roboto",
  },

  linkDonwnload: {
    color: '#fff',
    textDecoration: ' none',
    padding: '6px 20px',
    borderRadius: '50px',
  },
  buttonDonwnload: {
    padding: '0',
    borderRadius: '50px',
    color: '#fff',
  },

});

Font.register({
  family: "Roboto",
  src: fontRoboto,
});
Font.register({
  family: "RobotoBold",
  src: fontRobotoBold,
});

class TestPdfPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { testo: "-", listAlarm: [] };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const tmpLstAlarm = [
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#CB2953",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#EDA831",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#EDA831",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#EDA831",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#EDA831",
      },
      {
        time: "18:00",
        typealarm: "SecurityExceeded",
        people: "9/10",
        color: "#EDA831",
      },
    ];

    this.setState({
      listAlarm: tmpLstAlarm,
    });
  }

  render() {
    return (
      <div >

        <button onClick={this.handleClick}>INIT DATA PDF</button>

        <Button variant='contained' color='primary' style={styles.buttonDonwnload}>
          <PDFDownloadLink style={styles.linkDonwnload}
            document={
              <Document>
                <Page size="A4" style={styles.body} wrap>
                  <View style={styles.boxLine}>
                    <Text style={styles.nameZone}>Zone</Text>
                    <Text style={styles.nameStore}>Restaurant</Text>
                    <Text style={styles.info}>
                      Restaurant sodexo Amundi, 90 boulevard Pasteur 75015 Paris
                  </Text>
                  </View>
                  <View style={styles.container}>
                    <View style={styles.leftColumn}>
                      <Text style={styles.title}>Affluenza giornaliera</Text>
                    </View>
                    <View style={styles.leftColumn}>
                      <Text style={styles.data}>10/10/2020</Text>
                    </View>
                  </View>
                  <Image style={styles.image} src={Closedboard} />
                  <View style={styles.containerStat}>
                    <View style={styles.container}>
                      <View style={styles.leftColumn}>
                        <Text style={styles.title}>Storico delgli avvisi</Text>
                      </View>
                      <View style={styles.leftColumn}>
                        <Text style={styles.data}>10/10/2020</Text>
                      </View>
                    </View>

                  </View>
                  {this.state.listAlarm.map((row) => (
                    <View style={styles.containerTable}>
                      <View style={styles.firstColumn}>
                        <Text style={styles.time}>{row.time}</Text>
                      </View>
                      <View style={styles.secondColumn}>
                        <Text style={styles.typeAlarm}>{row.typealarm}</Text>
                      </View>
                      <View style={styles.thirdColumn}>
                        <Text style={[styles.people, { color: row.color }]}>{row.people}</Text>
                      </View>
                    </View>


                  ))}
                  <Text
                    style={styles.footer}
                    render={({ pageNumber, totalPages }) =>
                      `${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </Page>
              </Document>
            }
            fileName="somename.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>
        </Button>
      </div>
    );
  }
}

export default withTranslation()(TestPdfPage);
