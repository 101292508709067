import React from 'react';
import {Container } from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import logoimok from '../../images/Logoimok.png';

const styles = theme =>  ({
  root: {
    flexGrow: 1,
  },
  logo: {
    maxWidth:'180px',
    margin: '70px auto 10px',
    width:'100%',
},

});

class LogoHeader extends React.Component {
   
    render() {
      const { classes } = this.props;
    return (
    <Container maxWidth="lg" align='center'>
    <img src={logoimok} alt="Logo"  className={classes.logo} />
      </Container>

    )

}
}


export default withStyles(styles)(LogoHeader);