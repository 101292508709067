import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";



const styles = (theme) => ({
  root: {
    margin: 0,
    flexGrow: 1,
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    alignItems: "center",
    display: "flex",
  },
  nameZonestyle: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
});



class Closed extends Component {
  loadFonts(template) {
    const font = new FontFace("fontSelected", `url(${template.font_url})`);
    // add font to document
    document.fonts.add(font);
  }

  render() {
    const { classes,  template, regionName } = this.props;
    this.loadFonts(template);
    return (
      <Box>
        <Box
          className={classes.root}
          style={{ backgroundImage: `url(${template.image_closed})` }}
        >
          
          {template.closed && template.closed.show_namezone === true &&
            <Typography
              className={classes.nameZonestyle}
              variant="h1"
              style={{
                fontFamily: "fontSelected",
                color: template.closed.font_color_namezone,
                top: template.closed.margintop_namezone,
                fontSize: template.closed.font_size_namezone,
              }}
            >
             {regionName.substring(0,15)} 
            </Typography>
          }

        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(Closed));
