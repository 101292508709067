import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Container, Button } from '@material-ui/core';
import MainNavigation from '../components/header/MainNavigation'
import Footer from '../components/footer/Footer'
import AlarmList from '../components/zoneComponents/AlarmList'
import TimelineButton from '../components/zoneComponents/TimelineButton'
import StoreInformation from '../components/zoneComponents/StoreInformation'
import RealTimeAffluence from '../components/zoneComponents/RealTimeAffluence'
import DailyStats from '../components/zoneComponents/DailyStats'
import CircularLoading from '../components/Progress/CircularLoading';
import * as CommonApi from '../utils/common-api'
import * as CommonStorage from '../utils/common-storage'
import { withRouter } from "react-router";
import moment from "moment"


const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 64px)',
  },

}
);


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = { screen: 'page', zone: [], current_zone: {}, current_place: {}, realtime_desc: '', affluence: 0, people_max: 0, percentage_people: 0, list_daily_affluance: [], date_now: moment().format('DD/MM/YYYY'), list_daily_alarm: [], show_toolbar: false, show_zone_listmenu: false };
    this.refreshPage = this.refreshPage.bind(this)
    this.initData = this.initData.bind(this)
  }


  refreshPage() {
    this.initData(true)
  }

  componentDidMount() {
    this.initData(false)
  }

  async initData(is_refresh) {
    const { history } = this.props;
    try {

      this.setState({ screen: 'loading' });

      this.setState({ show_toolbar: is_refresh });


      //
      //GET PLACE AND ZONE
      //
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: 'GET',
      };
      var response = await fetch(CommonApi.getBaseUrlApi() + "/places-with-zone", requestOptions)
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          console.log("A problem has occurred")
          history.push("/login")
        } else if (response.status === 401) {
          history.push("/login")
        } else {
          console.log("A problem has occurred")
          history.push("/login")
        }
      } else {
        //OK
        if (data !== null && data.length > 0) {

          var allRegions = []
          data[0].regions.map((region) => (
            allRegions.push(region)
          ))


          this.setState({ place: { name: data[0].name, description: data[0].description } });


          //Verify show list menu
          if (data[0].regions.length > 1) {
            this.setState({ show_zone_listmenu: true });
          }


          //Stored select place zone
          CommonStorage.setCurrentPlace(data[0])


          if (CommonStorage.getCurrentZone() === null) {
            if (data[0].regions !== null && data[0].regions.length > 0) {
              CommonStorage.setCurrentZone(data[0].regions[0])
            }
          } else {
            data[0].regions.map((region) => {
              if (CommonStorage.getCurrentZone().id == region.id) {
                CommonStorage.setCurrentZone(region)
              }
            }
            )
          }
        }

        var currentZone = CommonStorage.getCurrentZone()
        this.setState({ zone: allRegions });
        this.setState({ current_zone: currentZone });
        this.setState({ current_place: data[0] });


        //show toolbar
        this.setState({ show_toolbar: true });



        //
        //GET NOW IN PLACE
        //
        const requestFluidityNow = {
          ...CommonApi.getRequestOptions(),
          method: 'GET',
        };

        var responseFluidityNow = await fetch(CommonApi.getBaseUrlApi() + "/fluidity-now-live?date=" + moment().toISOString() + "&region_id=" + currentZone.id, requestFluidityNow)
        const dataFluidityNow = await responseFluidityNow.json();
        if (!responseFluidityNow.ok) {
          if (responseFluidityNow.status === 403) {
            console.log("A problem has occurred")
            history.push("/login")
          } else if (responseFluidityNow.status === 401) {
            //Redirect to login
            history.push("/login")
          } else {
            //Error
            console.log("A problem has occurred")
            history.push("/login")
          }
        } else {
          //OK
          if (dataFluidityNow !== null) {
            let progress = (100 / dataFluidityNow.max_capacity) * dataFluidityNow.count;
            if (progress > 100) {
              progress = 100;
            };
            this.setState({ affluence: dataFluidityNow.count, people_max: dataFluidityNow.max_capacity, percentage_people: progress });
          }
        }


        //
        //GET FLIDITY
        //
        const requestFluidityLive = {
          ...CommonApi.getRequestOptions(),
          method: 'GET',
        };

        const dateFrom = moment().subtract(5, 'hours')
        var responseFluidityLive = await fetch(CommonApi.getBaseUrlApi() + "/fluidity-live?date_from=" + dateFrom.toISOString() + "&date_to=" + moment().toISOString() + "&region_id=" + currentZone.id, requestFluidityLive)
        const dataFluidityLive = await responseFluidityLive.json();
        if (!responseFluidityLive.ok) {
          if (responseFluidityLive.status === 403) {
            console.log("A problem has occurred")
            history.push("/login")
          } else if (responseFluidityLive.status === 401) {
            //Redirect to login
            history.push("/login")
          } else {
            //Error
            console.log("A problem has occurred")
            history.push("/login")
          }
        } else {
          //OK
          if (dataFluidityLive !== null) {
            const dataListFluidity = []
            dataFluidityLive.items.map((row) => (
              dataListFluidity.push({ hours: new moment(row.date).format('HH:mm'), people: row.count, peopleMax: dataFluidityLive.max_capacity, securityLimit: dataFluidityLive.alert_capacity })
            ));

            this.setState({ list_daily_affluance: dataListFluidity });
          }
        }



        //
        //GET ALERT HISTORY
        //
        const requestAlertHistory = {
          ...CommonApi.getRequestOptions(),
          method: 'GET',
        };

        var responseAlertHistory = await fetch(CommonApi.getBaseUrlApi() + "/alert-history?date=" + moment().toISOString() + "&region_id=" + currentZone.id, requestAlertHistory)
        const dataAlertHistory = await responseAlertHistory.json();
        if (!responseAlertHistory.ok) {
          if (responseAlertHistory.status === 403) {
            console.log("A problem has occurred")
            history.push("/login")
          } else if (responseAlertHistory.status === 401) {
            //Redirect to login
            history.push("/login")
          } else {
            //Error
            console.log("A problem has occurred")
            history.push("/login")
          }
        } else {
          //OK
          if (dataAlertHistory !== null) {
            const dataListAlertHistory = []


            dataAlertHistory.map((row) => (
              dataListAlertHistory.push({ time: new moment(row.date).format('HH:mm'), typealarm: row.kind === 'MAX' ? 'SecurityExceeded' : 'SecurityReached', people: row.capacity_description, color: row.kind === 'MAX' ? 'primary' : 'error' })
            ));

            this.setState({ list_daily_alarm: dataListAlertHistory });
          }
        }


        //START TIMER 1 MINUTES
        if (this.intervalId != null) {
          clearInterval(this.intervalId);
        }
        this.intervalId = setInterval(this.timer.bind(this), 30000);


        //SHOW PAGE
        this.setState({ screen: 'page' });
      }


    } catch (error) {
      console.log("A problem has occurred")
      history.push("/login")
    }
  }




  componentWillUnmount() {
    clearInterval(this.intervalId);
  }



  async timer() {
    const { history } = this.props;
    var current_zone = CommonStorage.getCurrentZone()

    try {


      //
      //GET NOW IN PLACE
      //
      const requestFluidityNow = {
        ...CommonApi.getRequestOptions(),
        method: 'GET',
      };

      var responseFluidityNow = await fetch(CommonApi.getBaseUrlApi() + "/fluidity-now-live?date=" + moment().toISOString() + "&region_id=" + current_zone.id, requestFluidityNow)
      const dataFluidityNow = await responseFluidityNow.json();
      if (!responseFluidityNow.ok) {
        if (responseFluidityNow.status === 403) {
          console.log("A problem has occurred")
        } else if (responseFluidityNow.status === 401) {
          //Redirect to login
          history.push("/login")
        } else {
          //Error
          console.log("A problem has occurred")
        }
      } else {
        //OK
        if (dataFluidityNow !== null) {
          let progress = (100 / dataFluidityNow.max_capacity) * dataFluidityNow.count;
          if (progress > 100) {
            progress = 100;
          };
          this.setState({ affluence: dataFluidityNow.count, people_max: dataFluidityNow.max_capacity, percentage_people: progress });
        }
      }


      //
      //GET FLIDITY
      //
      const requestFluidityLive = {
        ...CommonApi.getRequestOptions(),
        method: 'GET',
      };
      const dateFrom = moment().subtract(5, 'hours')
      var responseFluidityLive = await fetch(CommonApi.getBaseUrlApi() + "/fluidity-live?date_from=" + dateFrom.toISOString() + "&date_to=" + moment().toISOString() + "&region_id=" + current_zone.id, requestFluidityLive)
      const dataFluidityLive = await responseFluidityLive.json();
      if (!responseFluidityLive.ok) {
        if (responseFluidityLive.status === 403) {
          console.log("A problem has occurred")
        } else if (responseFluidityLive.status === 401) {
          history.push("/login")
        } else {
          console.log("A problem has occurred")
        }
      } else {
        //OK
        if (dataFluidityLive !== null) {
          const dataListFluidity = []
          dataFluidityLive.items.map((row) => (
            dataListFluidity.push({ hours: new moment(row.date).format('HH:mm'), people: row.count, peopleMax: dataFluidityLive.max_capacity, securityLimit: dataFluidityLive.alert_capacity })
          ));

          this.setState({ list_daily_affluance: dataListFluidity });
        }
      }


      //
      //GET ALERT HISTORY
      //
      const requestAlertHistory = {
        ...CommonApi.getRequestOptions(),
        method: 'GET',
      };

      var responseAlertHistory = await fetch(CommonApi.getBaseUrlApi() + "/alert-history?date=" + moment().toISOString() + "&region_id=" + current_zone.id, requestAlertHistory)
      const dataAlertHistory = await responseAlertHistory.json();
      if (!responseAlertHistory.ok) {
        if (responseAlertHistory.status === 403) {
          console.log("A problem has occurred")
        } else if (responseAlertHistory.status === 401) {
          //Redirect to login
          history.push("/login")
        } else {
          console.log("A problem has occurred")
        }
      } else {
        //OK
        if (dataAlertHistory !== null) {
          const dataListAlertHistory = []
          dataAlertHistory.map((row) => (
            dataListAlertHistory.push({ time: new moment(row.date).format('HH:mm'), typealarm: row.kind === 'MAX' ? 'SecurityExceeded' : 'SecurityReached', people: row.capacity_description, color: row.kind === 'MAX' ? 'primary' : 'error' })
          ));

          this.setState({ list_daily_alarm: dataListAlertHistory });
        }
      }


    } catch (error) {
      console.log("A problem has occurred")
    }

  }



  render() {
    const { classes } = this.props;
    return (
      <div>


        <MainNavigation showToolbar={this.state.show_toolbar} showZoneListMenu={this.state.show_zone_listmenu} refreshPage={this.refreshPage} currentPlace={this.state.current_place} currentZone={this.state.current_zone} Zone={this.state.zone} dateNow={this.state.date_now} />
        <StoreInformation currentPlace={this.state.current_place} />


        {this.state.screen === 'loading' && (
          <div className={classes.root}>
            <CircularLoading />
          </div>
        )}
        {this.state.screen === 'page' && (
          <div>


            <Container >
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}  >
                  <RealTimeAffluence affluence={this.state.affluence} peopleMax={this.state.people_max} percentagePeople={this.state.percentage_people} />
                  <DailyStats listDailyAffluance={this.state.list_daily_affluance} dateNow={this.state.date_now} />
                </Grid>
                <Grid item md={6} xs={12}>
                  <AlarmList listDailyAlarm={this.state.list_daily_alarm} dateNow={this.state.date_now} />
                </Grid>
              </Grid>
            </Container>
            <TimelineButton />
          </div>
        )}
        <Footer />
      </div>
    )
  }
}


MainPage.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};


export default withStyles(styles)(withRouter(MainPage));
