import React from "react";
import {
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  Container,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { ArrowBack } from "@material-ui/icons";
import { withRouter } from "react-router";


const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
});

class BackArrowNavigation extends React.Component {
  HandleClickIcon = (event) => {
    const { pathname } = this.props.location;
    const { history } = this.props;
    if ((pathname === '/setting') || (pathname === '/pdfhistory')) {
      history.push("/")
    } else if (pathname === '/downloadpdf') {
      history.push("/pdfhistory")
    }
    else {
      history.push("/")
    }

  };

  render() {
    const { classes, zoneName } = this.props;


    return (
      <AppBar position="fixed" color="primary">
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClickCapture={this.HandleClickIcon}
            >
              <ArrowBack />
            </IconButton>
            <Typography className={classes.title} color="inherit" variant="h5">
              <strong>{zoneName}</strong>
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(BackArrowNavigation));
