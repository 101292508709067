import React, { Component } from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  FilledInput,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  withStyles
} from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import * as CommonApi from '../../utils/common-api'
import { withRouter } from "react-router";
import queryString from 'query-string';

const styles = (theme) => ({
  root: {
    paddingTop: "20px",
    paddingBottom: "30px",
    flexGrow: 1,
  },
  margin: {
    marginTop: theme.spacing(4),
  },

  marginGrid: {
    margin: "30px auto",
  },
  button: {
    padding: "8px 60px",
    marginTop: "10px",
  },
  warning: {
    maxWidth: 260,
  }
});

class SetNewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repeatPassword: "",
      newPassword: "",
      showPassword: false,
      helperText: "",
      hashcode: ""
    };

    this.ClickNewPassword = this.ClickNewPassword.bind(this);
    this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
    this.handleChangeRepeatPassword = this.handleChangeRepeatPassword.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }

  handleChangeNewPassword = (event) => {
    this.setState({ newPassword: event.target.value });
  };

  handleChangeRepeatPassword = (event) => {
    this.setState({ repeatPassword: event.target.value });
  };
  //search="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}";

  // return result.match(new RegExp(`${search}`,'gi'));


  ClickNewPassword = async (event) => {
    if (this.state.newPassword !== this.state.repeatPassword) {
      this.setState({ helperText: "description.ComparisonPassword" });
      return;
    }

    else if (! /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(this.state.newPassword)) {
      this.setState({ helperText: "description.ControlPassword" });
      return;
    }
    else if ((this.state.newPassword.length + this.state.repeatPassword.length) === 0) {
      this.setState({ helperText: "description.EmptyPassword" });
      return;
    }
    else {
      this.setState({ helperText: "" });
    }


    const { history } = this.props;
    //Call api login
    try {
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: 'POST',
        body: JSON.stringify({ 'new-password': this.state.newPassword, 'cod-change-password': this.state.hashcode })
      };
      var response = await fetch(CommonApi.getBaseUrlApi() + "/change-password", requestOptions)
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          this.setState({ helperText: "description.GenericError" });
        } else {
          this.setState({ helperText: "description.GenericError" });
        }
      } else {
        history.push("/login")
      }
    } catch (error) {
      this.setState({ helperText: "description.GenericError" });
    }



  };
  onKeyPress = (e) => {
    if(e.which === 13) {
      this.ClickNewPassword();
    }  }

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  componentDidMount() {
    let params = queryString.parse(this.props.location.search)
    if (params.hashcode !== "") {
      this.setState({ hashcode: params.hashcode });
    }
  }

  render() {
    const { classes, t } = this.props;



    return (

      <Grid
        xs={12}
        container
        className={classes.root}
        alignContent="center"
      >
          <Grid
          item
          align="center"
          xs={12}
          sm={4}
          className={classes.marginGrid}
        >
          <Typography color="primary" variant="h6">
            <strong>{t(`description.SetNewPassword`)}</strong>
          </Typography>
          <Typography color="inherit" variant="body1">
            {t(`description.ControlPassword`)}
          </Typography>
        </Grid>
        <Grid item xs={12} align="center">
          <FormControl variant="filled">
            <InputLabel
              htmlFor="Password"
              className={classes.margin}
            >
              {t(`description.NewPassword`)}
            </InputLabel>
            <FilledInput
             onKeyPress={this.onKeyPress}
              id="Password"
              type={this.state.showPassword ? "text" : "password"}
              onChange={this.handleChangeNewPassword}
              value={this.state.newPassword}
              className={classes.marginGrid}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword} >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                        <VisibilityOff />
                      )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

        </Grid>
        <Grid item xs={12} align="center">
          <FormControl variant="filled">
            <InputLabel
              htmlFor="Repeat-password"
              className={classes.margin}
            > {t(`description.RepeatPassword`)}
            </InputLabel>
            <FilledInput
             onKeyPress={this.onKeyPress}
              label={t(`description.RepeatPassword`)}
              id="Repeat-password"
              type={this.state.showPassword ? "text" : "password"}
              value={this.state.repeatPassword}
              onChange={this.handleChangeRepeatPassword}
              className={classes.marginGrid}
              label={t(`description.RepeatPassword`)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                        <VisibilityOff />
                      )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>

        <Grid container alignItems="center" xs={12} justify="center" >
          <Box className={classes.warning}>
            <Typography align="center" color="error">{t(`${this.state.helperText}`)}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} align="center">
          <Button
            href='#'
            onClick={this.ClickNewPassword}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {t(`description.ChangePassword`)}
          </Button>
        </Grid>
      </Grid>

    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(SetNewPassword)));
