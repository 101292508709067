import { createMuiTheme } from "@material-ui/core/styles";


const theme = createMuiTheme({

  palette: {
    primary: { 500: '#CB2953' },
    error:{ 500: '#EDA831'},

  }, 
overrides: {
    
  MuiDrawer: {
    paperAnchorLeft:{
    width: "100%",
    maxWidth: "500px",
    },
     },

    MuiTypography: {
      body1: {
        color: '#6C6F7A',
      },
      body2: {
        color: '#6C6F7A',
      },
      h1:{ 
        fontSize:'3.5em;'
      },
      h2:{
        fontSize:'1.6em;'
      },
      colorTextSecondary:{
        color: '#FFF',
      },
     
  },

  MuiInputBase:{
      root:{
    '&$disabled':{
      backgroundColor: '#e3e3e3',
    },
  },
},

 MuiSwitch:{
        thumb :{
          width: 30,
           height: 30,
          },
        root:{
          width: '70px',
          height: '50px', 
        },
        track: {
          backgroundColor: '#E3E3E3',
          opacity: 1,
          borderRadius: '12px',
        },  
        switchBase:{
          padding: 5,
          height: 40,
          width: 40,
          color: '#666974',
        },
        colorPrimary:{
        '&$checked + $track': {
          opacity:1,
          backgroundColor:"#E3E3E3",
        },
        '&$disabled + $track': {
          opacity: 0.5,
          backgroundColor: '#e2e2e2',
    }
      },

    
    

},


MuiFilledInput:{
  root:{
    borderRadius: '30px 30px',
    backgroundColor: '#dcdcdc',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    }
,   underline:{ 
      "&&&:before": {
        borderBottom: "none"
      },
      "&&:after": {
        borderBottom: "none"
      }
  },
},
MuiTouchRipple:{
  root:{
  color:'#d3d3d3',
  }
},

    MuiButton: {
      contained: {
        padding: '5px 30px',
        borderRadius: '50px',
      },
    
    },
 },

 
 MuiTypography:{
   h2: {
  fontSize:" 1.5rem",
  color: "#FFF"
}
},
})


export default theme