
export function setCurrentPlace(place){
    localStorage.setItem('current_place', JSON.stringify(place));
}
  

export function setCurrentZone(zone){
    localStorage.setItem('current_zone', JSON.stringify(zone));
}
  

export function setExpandZoneMenu(enable){
    localStorage.setItem('expand_zone_menu', enable);
}
  

export function getExpandZoneMenu(){
    return localStorage.getItem('expand_zone_menu');
}


export function getCurrentPlace(){
    return JSON.parse(localStorage.getItem('current_place'));
}
  

export function getCurrentZone(){
    return JSON.parse(localStorage.getItem('current_zone'));
}


export function clearAll(){
    localStorage.clear()
}