import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import BackArrowNavigation from '../components/header/BackArrowNavigation'
import Footer from '../components/footer/Footer'
import GeneralSettings from '../components/settingsComponents/GeneralSettings'
import CircularLoading from '../components/Progress/CircularLoading';
import * as CommonStorage from '../utils/common-storage'

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 'calc(100vh - 64px)',
  },

}
);


class SettingsPage extends Component {
  state = {
    screen: 'page',
    zone_name: "",
  }

  componentDidMount() {
    var current_zone = CommonStorage.getCurrentZone()
    this.setState({ zone_name: current_zone.name });

  }



  render() {
    const { classes } = this.props;
    return (
      <div>
        <BackArrowNavigation zoneName={this.state.zone_name} />
        <Container className={classes.root}>
          {this.state.screen === 'loading' && (
            <CircularLoading />)}
          {this.state.screen === 'page' && (
            <GeneralSettings />
          )}
        </Container>
        <Footer />
      </div>
    )
  }
}

export default withStyles(styles)(SettingsPage);