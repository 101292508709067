import React, { Component } from "react";
import {
  Container,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Timeline } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    padding: 0,
    paddingTop: "90px",
    flexGrow: 1,
  },
  smallCell: {
    paddingLeft: 0,
  },
  notificationIcon: {
    color: '#cbcbcb',
    marginTop: theme.spacing(8),
    width: 80,
    height: 78,
    verticalAlign: "text-bottom",
  },
  emptyText: {
    color: '#6c6f7a',
  },
});


class DownloadPdfTable extends Component {
  state = {
    screen: "empty", //table
  };

  render() {
    const { classes, listReports, t } = this.props;
    return (

      <Container className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Typography color="primary" variant="h6">
              <strong>{t(`description.PdfHistory`)}</strong>
            </Typography>
          </Grid>
          {listReports.length > 0 && (
            <Table>
              <TableBody>
                {listReports.map((row) => (
                  <TableRow>
                    <TableCell align="left" className={classes.smallCell}>
                      <Typography variant="body1">
                        <strong>
                          {t(`description.Report`)}
                          {row.time}
                        </strong>
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        size="small"
                        href={row.linkPdf}
                      >
                        {t(`description.Show`)}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          {listReports.length <= 0 && (
            <Grid container>
              <Grid item xs={12} align="center">
                <Timeline className={classes.notificationIcon} />
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.emptyText}
                >
                  {t(`description.ReportEmpty`)}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>

    );
  }
}

export default withStyles(styles)(withTranslation()(DownloadPdfTable));
