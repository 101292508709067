import React, { Component } from 'react';
import MainPage from './pages/MainPage';
import SettingsPage from './pages/SettingsPage';
import ListPdfPage from './pages/ListPdfPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SentPasswordPage from './pages/SentPasswordPage';
import SetNewPasswordPage from './pages/SetNewPasswordPage';
import PdfPage from './pages/PdfPage';
import DisplayPage from './pages/DisplayPage';
import './styles/App.css';
import theme from './themes/theme'
import { ThemeProvider } from '@material-ui/core/styles';
import CircularLoading from './components/Progress/CircularLoading'
import { Route, BrowserRouter as Router } from 'react-router-dom'
import InitPage from './pages/InitPage';
import TestPdfPage from './pages/TestPdfPage';




class App extends Component {

  render() {
    return (
      <Router>
        <ThemeProvider theme={theme}>

          <Route exact path="/login" >
            <LoginPage />
          </Route>
          <Route exact path="/forgotpassword" >
            <ForgotPasswordPage />
          </Route>
          <Route exact path="/sentpassword" >
            <SentPasswordPage />
          </Route>
          <Route exact path="/setnewpassword" >
            <SetNewPasswordPage />
          </Route>
          <Route exact path="/" >
            <MainPage />
          </Route>
          <Route exact path="/pdfhistory" >
            <ListPdfPage />
          </Route>
          <Route exact path="/setting" >
            <SettingsPage />
          </Route>
          <Route exact path="/downloadpdf" >
            <PdfPage />
          </Route>
          <Route exact path="/display" >
            <DisplayPage />
          </Route>
          <Route exact path="/test" >
            <InitPage />
          </Route>
          <Route exact path="/testpdfpage" >
            <TestPdfPage />
          </Route>
        </ThemeProvider>

      </Router>
    );
  }
}



//<MainPage/>
//<CircularLoading/>
//<SentPasswordPage/>
//<PdfHistoryPage/>
//<SettingsPage/>
//<LoginPage/>
//<ForgotPasswordPage/>
//SetNewPasswordPage

export default App;
