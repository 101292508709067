import React, { Component } from "react";
import {
  withStyles
} from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import BackArrowNavigation from "../components/header/BackArrowNavigation";
import Footer from "../components/footer/Footer";
import DownloadPdfTable from "../components/pdfHistoryComponents/DownloadPdfTable";
import { Translation } from "react-i18next";
import CircularLoading from '../components/Progress/CircularLoading';
import * as CommonApi from '../utils/common-api'
import * as CommonStorage from '../utils/common-storage'
import { withRouter } from "react-router";
import moment from "moment"


const styles = (theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "calc(100vh - 64px)",
  },
});

class ListPdfPage extends Component {
  state = {
    screen: 'loading',
  }

  async componentDidMount() {
    const { history } = this.props;
    try {

      this.setState({ screen: 'loading', list_reports: [], zone_name: "" });
      var current_zone = CommonStorage.getCurrentZone()


      this.setState({ zone_name: current_zone.name });
      //
      //GET PLACE AND ZONE
      //
      const requestOptions = {
        ...CommonApi.getRequestOptions(),
        method: 'GET',
      };
      var response = await fetch(CommonApi.getBaseUrlApi() + "/alert-reports?date=" + moment().toISOString() + "&region_id=" + current_zone.id + "&limit=30", requestOptions)
      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          console.log("A problem has occurred")
          //history.push("/login")
        } else if (response.status === 401) {
          history.push("/login")
        } else {
          console.log("A problem has occurred")
          //history.push("/login")
        }
      } else {
        //OK
        if (data !== null) {
          const dataListReportHistory = []
          data.map((row) => (
            dataListReportHistory.push({ time: new moment(row.DateReport).format('DD/MM/YYYY'), linkPdf: "/downloadpdf?date=" + row.DateReport })
          ));

          this.setState({ list_reports: dataListReportHistory });
        }






        //SHOW PAGE
        this.setState({ screen: 'page' });
      }


    } catch (error) {
      console.log("A problem has occurred")
      history.push("/login")
    }
  }



  render() {
    const { classes } = this.props;

    return (
      <div>
        <BackArrowNavigation zoneName={this.state.zone_name} />

        <Container maxWidth="md" className={classes.root}>
          {this.state.screen === 'loading' && (
            <CircularLoading />)}
          {this.state.screen === 'page' && (
            <DownloadPdfTable listReports={this.state.list_reports} />
          )}
        </Container>
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(ListPdfPage));
