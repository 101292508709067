import React, { Component } from 'react';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles, } from '@material-ui/core/styles';
import { Grid, Container } from '@material-ui/core';
import LogoHeader from '../components/header/LogoHeader'
import ForgotPasswordForm from '../components/login/ForgotPasswordForm';
import CircularLoading from '../components/Progress/CircularLoading';
const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
  },

}
);

class ForgotPasswordPage extends Component {
  state = {
    screen: 'page',
  }
  render() {
    return (
      <div>
        <LogoHeader />
        <Container >
          {this.state.screen === 'loading' && (
            <CircularLoading />)}
          {this.state.screen === 'page' && (
            <Grid container spacing={0} alignContent='center'>
              <Grid item xs={12}  >
                <ForgotPasswordForm />
              </Grid>
            </Grid>
          )}
        </Container>
      </div>
    )
  }
}



export default withStyles(styles)(ForgotPasswordPage);