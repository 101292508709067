import React, { Component } from "react";
import {
  Container,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Today, NotificationsNone } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    paddingTop: "20px",
    flexGrow: 1,
  },
  smallCell: {
    paddingLeft: 0,
    width: 60,
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 18,
    verticalAlign: "text-bottom",
  },
  notificationIcon: {
    color: "#cbcbcb",
    marginTop: theme.spacing(3),
    width: 80,
    height: 78,
    verticalAlign: "text-bottom",
  },
  emptyText: {
    color: "#6c6f7a",
    marginBottom: theme.spacing(6),
    borderBottom: "1px solid #cbcbcb",
    paddingBottom: theme.spacing(3),
  },
});



class AlarmList extends Component {

  render() {
    const { classes, listDailyAlarm, dateNow, t } = this.props;
    return (

      <Container className={classes.root}>
        <Grid container>
          <Grid item xs={6}>
            <Typography color="primary" variant="h6">
              <strong>{t(`description.AlertHistory`)}</strong>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              <Today className={classes.icon} />
              {dateNow}
            </Typography>
          </Grid>
        </Grid>
        {listDailyAlarm.length > 0 && (
          <Table>
            <TableBody>
              {listDailyAlarm.map((row) => (
                <TableRow>
                  <TableCell align="left" className={classes.smallCell}>
                    <Typography variant="body1">
                      <strong>{row.time}</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body2">
                      {t(`description.${row.typealarm}`)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h6" color={row.color}>
                      <strong>{row.people}</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        {listDailyAlarm.length <= 0 && (
          <Grid container>
            <Grid item xs={12} align="center">
              <NotificationsNone className={classes.notificationIcon} />
              <Typography
                variant="h5"
                align="center"
                className={classes.emptyText}
              >
                {t(`description.AlertEmpty`)}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Container>

    );
  }
}

export default withStyles(styles)(withTranslation()(AlarmList));
