import React, { Component } from 'react';
import { Grid, Typography, Container,Divider} from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';


const styles = theme =>  ({
  root: {
    paddingBottom:'30px',
    textAlign: 'left' ,
  },
 
});



class PdfInformation extends Component {

    render() {
      const { classes, zoneName, placeName, placeDescription } = this.props;
    return (
      <div  className={classes.root}>
                <Typography variant="h1" align="left" color='Primary'>
                <strong>{zoneName}</strong>
              </Typography>
              <Typography variant="h2" align="left" color='standard'>
                <strong>{placeName}</strong>
              </Typography>
              <Typography variant="body1" align="left" color='standard'>
              {placeDescription}
              </Typography>
              <Divider />
       
      </div>

    )
}
}




export default withStyles(styles)(PdfInformation);