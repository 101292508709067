import React, { Component } from "react";
import * as CommonApi from "../../utils/common-api";
import * as CommonStorage from "../../utils/common-storage";
import { withRouter } from "react-router";
import moment from "moment";

import {
  Grid,
  Typography,
  Container,
  TextField,
  Switch,
  Button,
  Box,
  OutlinedInput,
  InputAdornment,
  FormControl,
  InputLabel,
  Icon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Email } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  root: {
    paddingTop: "90px",
    paddingBottom: "30px",
    flexGrow: 1,
  },
  littleInput: {
    display: "inline-block",
    width: "50px",
    marginRight: theme.spacing(2),
  },

  grid: {
    marginTop: "30px",
    marginBottom: "30px",
  },
  caption: {
    fontSize: "0.68em",
    padding: "5px 13px 5px 0",
    display: " block",
    lineHeight: "1.3",
  },
  button: {
    padding: "8px 60px",
    marginTop: "40px",
  },
  iconMail: {
    color: "#656565",
    padding: "10px",
  },
});

class GeneralSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchDisplay: true,
      switchMonitorPeople: false,
      switchdisabled: false,
      maxCapacity: 7,
      alertLevel: 6,
      email: "",
      helperText: "",
      switchAlert: false,
      emailDisabled: true,
    };
    this.handleChangeAlertinput = this.handleChangeAlertinput.bind(this);
    this.handleChangeMaxCapacity = this.handleChangeMaxCapacity.bind(this);
    this.handleswitchDisplay = this.handleswitchDisplay.bind(this);
    this.handleMonitorPeople = this.handleMonitorPeople.bind(this);
    this.handleClickSave = this.handleClickSave.bind(this);
  }

  focusAlertinput = (event) => {
    event.currentTarget.select();
  };

  blurAlertinput = (event) => {
    if (event.target.value <= 1) {
      this.setState({ alertLevel: 1 });
    }
  };

  clickAlertinput = (event) => {
    if (event.target.value <= 1) {
      this.setState({ alertLevel: 1 });
    }
  };
  handleChangeAlertinput = (event) => {
    this.setState({ alertLevel:  String(event.target.value).replace(/\b0+/g, '') });
    if (this.state.maxCapacity - Number(event.target.value) < 0) {
      this.setState({ maxCapacity: Number(event.target.value) });
    }
   
  };
  focusMaxCapacity = (event) => {
    event.currentTarget.select();
  };

  blurMaxCapacity = (event) => {
    if (Number(event.target.value) <= 1) {
      this.setState({ maxCapacity: 1 });
    }
    if (Number(this.state.maxCapacity) <= 1) {
      this.setState({ alertLevel: 1 });
    }
    Number(this.state.maxCapacity)
  };


  clickMaxCapacity = (event) => {
    if (Number(event.target.value) <= 1) {
      this.setState({ maxCapacity: 1 });
    }
    if (Number(this.state.maxCapacity) <= 1) {
      this.setState({ alertLevel: 1 });
    }
    
  };
  handleChangeMaxCapacity = (event) => {
    this.setState({ maxCapacity:  String(event.target.value).replace(/\b0+/g, '') });
    if (Number(this.state.alertLevel) - Number(event.target.value) > 0) {
      this.setState({ alertLevel: Number(event.target.value) });
    }
    if (Number(this.state.maxCapacity) === 0) {
      this.setState({ alertLevel: 1 });
    }
  };

  handleswitchDisplay = (event) => {
    this.setState({ switchDisplay: event.target.checked });
    if (this.state.switchDisplay === true) {
      this.setState({ switchdisabled: true });
    } else {
      this.setState({ switchdisabled: false });
    }
  };

  handleswitchEmail = (event) => {
    this.setState({ switchAlert: event.target.checked });
    if (this.state.switchAlert === true) {
      this.setState({ emailDisabled: true });
    } else {
      this.setState({ emailDisabled: false });
    }
    if (this.state.switchAlert === true) {
      this.setState({ helperText: "" });
    }
  };

  handleMonitorPeople = (event) => {
    this.setState({ switchMonitorPeople: event.target.checked });
  };

  handleClickSave = async (event) => {
    if (this.state.switchAlert === true) {
      if (this.state.email.length === 0) {
        this.setState({ helperText: "description.EmptyMail" });
        return;
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)
      ) {
        this.setState({ helperText: "description.NotAMail" });
        return;
        // } else if (this.state.email != "user@imok.it") {
        //    this.setState({ helperText: "description.EmailError" });
      } else {
        this.setState({ helperText: "" });
      }
    } else {
      this.setState({ helperText: "" });
    }

    //SAVE
    const { history } = this.props;
    var current_zone = CommonStorage.getCurrentZone();
    try {
      const requestSettings = {
        ...CommonApi.getRequestOptions(),
        method: "POST",
        body: JSON.stringify({
          max_capacity: Number(this.state.maxCapacity),
          region_id: current_zone.id,
          alert_capacity: Number(this.state.alertLevel),
          email_alert: this.state.email,
          display_counter: this.state.switchMonitorPeople,
          display_on: this.state.switchDisplay,
          email_alert_on: this.state.switchAlert,
        }),
      };
      var responseSettings = await fetch(
        CommonApi.getBaseUrlApi() + "/zone-settings-update",
        requestSettings
      );
      //const dataSettings = await responseSettings.json();
      if (!responseSettings.ok) {
        if (responseSettings.status === 403) {
          this.setState({ helperText: "description.GenericError" });
        } else if (responseSettings.status === 401) {
          history.push("/login");
        } else {
          this.setState({ helperText: "description.GenericError" });
        }
      } else {
        //OK
        history.push("/");
      }
    } catch (error) {
      console.log("A problem has occurred");
    }
  };

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  async componentDidMount() {
    const { history } = this.props;
    var current_zone = CommonStorage.getCurrentZone();

    //
    //GET SETTINGS
    //
    try {
      const requestSettings = {
        ...CommonApi.getRequestOptions(),
        method: "GET",
      };
      var responseSettings = await fetch(
        CommonApi.getBaseUrlApi() +
          "/zone-settings?region_id=" +
          current_zone.id,
        requestSettings
      );
      const dataSettings = await responseSettings.json();
      if (!responseSettings.ok) {
        if (responseSettings.status === 403) {
          console.log("A problem has occurred");
        } else if (responseSettings.status === 401) {
          history.push("/login");
        } else {
          console.log("A problem has occurred");
        }
      } else {
        //OK
        if (dataSettings !== null) {
          this.setState({
            switchDisplay: dataSettings.display_on,
            switchMonitorPeople: dataSettings.display_counter,
            alertLevel: dataSettings.alert_capacity,
            maxCapacity: dataSettings.max_capacity,
            emailDisabled: !dataSettings.email_alert_on,
            email: dataSettings.email_alert,
            switchAlert: dataSettings.email_alert_on,
          });
        }
      }
    } catch (error) {
      console.log("A problem has occurred");
    }
  }

  render() {
    const { classes, t } = this.props;
    return (
      <Container maxWidth="lg" className={classes.root}>
        <form noValidate autoComplete="off">
          <Grid container justify="center">
            <Grid item xs={12}>
              <Typography color="primary" variant="h6">
                <strong>{t(`description.Settings`)}</strong>
              </Typography>
            </Grid>

            <Grid
              container
              sm={6}
              justify="flex-start"
              alignItems="center"
              className={classes.grid}
            >
              <Grid item sm={5} xs={6}>
                <Typography color="inherit" variant="body1">
                  {t(`description.SetMaxCapacity`)}
                </Typography>
              </Grid>
              <Grid item sm={7} xs={6}>
                <TextField
                  onFocus={this.focusMaxCapacity}
                  onBlur={this.blurMaxCapacity}
                  onClick={this.clickMaxCapacity}
                  onChange={this.handleChangeMaxCapacity}
                  type="number"
                  value={this.state.maxCapacity}
                  variant="standard"
                  id="maxC"
                  className={classes.littleInput}
                />
              </Grid>
            </Grid>

            <Grid
              container
              sm={6}
              alignItems="center"
              justify="flex-start"
              className={classes.grid}
            >
              <Grid item sm={5} xs={6}>
                <Typography color="inherit" variant="body1">
                  {t(`description.SetAlertLevel`)}
                </Typography>
              </Grid>
              <Grid item sm={7} xs={6}>
                <TextField
                onFocus={this.focusAlertinput}
                onBlur={this.blurAlertinput}
                onClick={this.clickAlertinput}
                  onChange={this.handleChangeAlertinput}
                  type="number"
                  value={this.state.alertLevel}
                  variant="standard"
                  id="AlertLevel"
                  className={classes.littleInput}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography color="primary" variant="h6">
                <strong>{t(`description.DisplaySetup`)}</strong>
              </Typography>
            </Grid>

            <Grid
              container
              alignItems="center"
              sm={6}
              justify="flex-start"
              className={classes.grid}
            >
              <Grid item sm={5} xs={6}>
                <Typography color="inherit" variant="body1">
                  {t(`description.Display`)}
                </Typography>
              </Grid>
              <Grid item sm={7} xs={6}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color="inherit" variant="caption">
                    Off
                  </Typography>
                  <Switch
                    checked={this.state.switchDisplay}
                    onChange={this.handleswitchDisplay}
                    name="checked"
                    color="primary"
                  />
                  <Typography color="inherit" variant="caption">
                    On
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              sm={6}
              justify="flex-start"
              className={classes.grid}
            >
              <Grid item sm={5} xs={6}>
                <Typography color="inherit" variant="body1">
                  {t(`description.DisplayCounter`)}
                </Typography>
              </Grid>
              <Grid item sm={7} xs={6}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color="inherit" variant="caption">
                    Off
                  </Typography>
                  <Switch
                    disabled={this.state.switchdisabled}
                    checked={this.state.switchMonitorPeople}
                    onChange={this.handleMonitorPeople}
                    name="checkMP"
                    color="primary"
                  />

                  <Typography color="inherit" variant="caption">
                    On
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography color="primary" variant="h6">
                <strong>{t(`description.AlertSetup`)}</strong>
              </Typography>
            </Grid>

            <Grid
              container
              alignItems="center"
              sm={6}
              justify="flex-start"
              className={classes.grid}
            >
              <Grid item sm={12} xs={12}>
                <Typography color="inherit" variant="body1">
                  {t(`description.SetEmailAddress`)}
                </Typography>
              </Grid>
              <Grid item sm={12} xs={12}>
                <FormControl variant="filled">
                  <InputLabel htmlFor="Email" className={classes.margin}>
                    {" "}
                    {t(`description.Email`)}
                  </InputLabel>
                  <OutlinedInput
                    disabled={this.state.emailDisabled}
                    id="Email"
                    type="email"
                    value={this.state.email}
                    onChange={this.handleChangeEmail}
                    className={classes.marginGrid}
                    endAdornment={
                      <InputAdornment position="end">
                        <Icon className={classes.iconMail}>
                          <Email />
                        </Icon>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container alignItems="center" sm={6} className={classes.grid}>
              <Grid container sm={5} xs={6}>
                <Typography color="initial" variant="body1">
                  {t(`description.ReceiveAlert`)}
                </Typography>
              </Grid>
              <Grid item sm={7} xs={6}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Typography color="inherit" variant="caption">
                    Off
                  </Typography>
                  <Switch
                    checked={this.state.switchAlert}
                    onChange={this.handleswitchEmail}
                    name="checkAlert"
                    color="primary"
                  />

                  <Typography color="inherit" variant="caption">
                    On
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center">
              <Box className={classes.warning}>
                <Typography align="center" color="error">
                  {t(`${this.state.helperText}`)}
                </Typography>
              </Box>
            </Grid>
            <Grid container justify="center">
              <Button
                className={classes.button}
                onClick={this.handleClickSave}
                href="#"
                variant="contained"
                color="primary"
              >
                {t(`description.Save`)}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    );
  }
}

export default withStyles(styles)(
  withRouter(withTranslation()(GeneralSettings))
);
