import React, { Component } from "react";
import { Button, Typography, Container } from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import fontRoboto from "../../fonts/roboto_regular.ttf";
import fontRobotoBold from "../../fonts/Roboto-Bold.ttf";
import Closedboard from "../../images/stat.png";
import CircularLoading from "../Progress/CircularLoading";
import logowhite from '../../images/LogoWhite.png';
import logoimok from '../../images/Logoimok.png';


const styles = (theme) => ({
  root: {
    paddingTop: "90px",
    paddingBottom: "30px",
    backgroundColor: "#eeeeee",
    textAlign: "center",
  },
  button: {
    padding: "8px 60px",
    marginTop: "40px",
  },
});


const stylesPDF = StyleSheet.create({
  body: {
    padding: '50px 0',
  },

  paddingHorizontal: {
    padding: '0 30px',
  },
  header:{
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: "row",
    width:'100%',
    position: "absolute",
    backgroundColor:'#CB2953',
  },
  logo:{
    align:'right',
    margin: '10px 30px',
width:60,
textAlign:'right',
float:'right',
  },
  nameZone: {
    fontSize: 36,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#CB2953",
  },
  nameStore: {
    fontSize: 16,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#000000",
  },
  info: {
    fontSize: 11,
    textAlign: "left",
    fontFamily: "Roboto",
    color: "#6C6F7A",
    paddingTop: 1,
  },

  boxLine: {
    paddingTop: 0,
    marginBottom: 10,
    paddingBottom: 0,
    width: "100%",
    borderBottomColor: "#E0E0E0",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  container: {
    flex: 1,
    flexDirection: "row",
    paddingTop: 20,
    marginBottom: 20,
    paddingBottom: 0,
    width: "100%",
  },

  leftColumn: {
    flexDirection: "column",
    width: "50%",
  },

  title: {
    float: "left",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "RobotoBold",
    color: "#CB2953",
    width: "100%",
  },
  data: {
    float: "right",
    fontSize: 12,
    textAlign: "right",
    fontFamily: "Roboto",
    color: "#6C6F7A",
    paddingTop: 4,
    width: "100%",
  },
  image: {
    height: "auto",
    marginBottom: 10,
    marginHorizontal: 0,
    width: "100%",
  },
  containerStat: {
    width: "100%",
  },
  containerTable: {
    alignSelf: 'center',
    alignItems: 'center',
    flex: 1,
    padding: '7px 5px 0 5px',
    maxHeight: '28px',
    minHeight: '28px',
    flexDirection: "row",
    width: "100%",
    borderBottomColor: "#E0E0E0",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",

  },
  firstColumn: {
    flexDirection: "column",
    width: "15%",
  },
  secondColumn: {
    flexDirection: "column",
    width: "55%",
  },
  thirdColumn: {
    flexDirection: "column",
    width: "30%",
    textAlign: "right",
  },
  time: {
    color: '#6C6F7A',
    fontSize: 12,
    fontFamily: "RobotoBold",
    textAlign: "left",
  },
  typeAlarm: {
    color: '#6C6F7A',
    fontSize: 11,
    fontFamily: "Roboto",
    textAlign: "left",
  },
  people: {
    fontSize: 13,
    fontFamily: "RobotoBold",
    textAlign: "right",
  },
  footer: {
    position: "absolute",
    fontSize: 12,
    bottom: 25,
    left: 35,
    right: 0,
    textAlign: "center",
    color: "grey",
    fontFamily: "Roboto",
  },

  linkDonwnload: {
    color: '#fff',
    textDecoration: ' none',
    padding: '6px 20px',
    borderRadius: '50px',
  },
  buttonDonwnloadNoClick: {
    padding: "0",
    marginTop: "30px",
    borderRadius: '50px',
    color: '#fff',
    pointerEvents: 'none',
    backgroundColor: '#969696',
  },

  buttonDonwnload: {
    padding: "0",
    marginTop: "30px",
    borderRadius: '50px',
    color: '#fff',
  },


});

Font.register({
  family: "Roboto",
  src: fontRoboto,
});
Font.register({
  family: "RobotoBold",
  src: fontRobotoBold,
});


class DownloadPdfButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_download: false,
    };

    this.buttonLoaded = this.buttonLoaded.bind(this);
  }



  buttonLoaded() {
    if (!this.state.show_download) {
      this.setState({ show_download: true })
    }
  }


  render() {


    const { classes, listAlarm, imageChart, dateNow, placeName, placeDescription, zoneName, t } = this.props;
    return (

      <Container maxWidth="false" className={classes.root}>
        <Typography variant="body1" align="center">
          <strong>{t(`description.DownloadThePdf`)}</strong>
        </Typography>
        <Button variant='contained' color='primary' style={this.state.show_download ? stylesPDF.buttonDonwnload : stylesPDF.buttonDonwnloadNoClick}>
          <PDFDownloadLink style={stylesPDF.linkDonwnload}
            document={
              <Document>
                <Page size="A4" style={stylesPDF.body} wrap>
                <View style={stylesPDF.header} fixed>
                <Image style={stylesPDF.logo} src={logowhite}  fixed />
                 </View>
                 <View style={stylesPDF.paddingHorizontal} >
                  <View style={stylesPDF.boxLine}>
                    <Text style={stylesPDF.nameZone}>{zoneName}</Text>
                    <Text style={stylesPDF.nameStore}>{placeName}</Text>
                    <Text style={stylesPDF.info}>
                      {placeDescription}
                    </Text>
                  </View>
                  <View style={stylesPDF.containerStat}>
                    <View style={stylesPDF.container}>
                      <View style={stylesPDF.leftColumn}>
                        <Text style={stylesPDF.title}>{t(`description.DailyAffluence`)}</Text>
                      </View>
                      <View style={stylesPDF.leftColumn}>
                        <Text style={stylesPDF.data}>{dateNow}</Text>
                      </View>
                    </View>
                    <Image style={stylesPDF.image} src={imageChart} />
                    {/*   Closedboard */}

                    <View style={stylesPDF.container}>
                      <View style={stylesPDF.leftColumn}>
                        <Text style={stylesPDF.title}>{t(`description.AlertHistory`)}</Text>
                      </View>
                      <View style={stylesPDF.leftColumn}>
                        <Text style={stylesPDF.data}>{dateNow}</Text>
                      </View>
                    </View>


                    {listAlarm.map((row) => (
                      <View style={stylesPDF.containerTable}>
                        <View style={stylesPDF.firstColumn}>
                          <Text style={stylesPDF.time}>{row.time}</Text>
                        </View>
                        <View style={stylesPDF.secondColumn}>
                          <Text style={stylesPDF.typeAlarm}>{t(`description.${row.typealarm}`)}</Text>
                        </View>
                        <View style={stylesPDF.thirdColumn}>
                          <Text style={[stylesPDF.people, { color: row.color }]}>{row.people}</Text>
                        </View>
                      </View>


                    ))}


                    {listAlarm.length <= 0 && (
                      <Text style={stylesPDF.time}>{t(`description.AlertEmpty`)}</Text>
                    )}

                  </View>
                 </View>
                  <Text
                    style={stylesPDF.footer}
                    render={({ pageNumber, totalPages }) =>
                      `${pageNumber} / ${totalPages}`
                    }
                    fixed
                  />
                </Page>
              </Document>
            }
            fileName="dmask_report.pdf">

            {({ blob, url, loading, error }) => {
              if (!loading) {
                this.buttonLoaded()
                return t(`description.Download`)
              } else {
                return "Loading..."
              }
            }

              // loading ? "Loading..." : t(`description.Download`)
            }


          </PDFDownloadLink>
        </Button>




      </Container>

    );
  }
}

export default withStyles(styles)(withTranslation()(DownloadPdfButton));
