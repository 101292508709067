import React, { Component } from 'react';
import { Grid, CircularProgress, LinearProgress, Typography} from '@material-ui/core';
import { createMuiTheme,  MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';


const styles = theme =>  ({
    root: {
      paddingTop: '150px',
      flexGrow: 1,
    },
 circular: {
  
},
  
      
  });


    class CircularLoading extends Component {

    render() {
      const { classes } = this.props;
    return (
         <Grid  container   className={classes.root}
        direction="row"
        justify="center"
        alignItems="center">
           <CircularProgress color="primary" className={classes.circular} />
         
         <Grid item xs={12} >
          <Typography variant="body2" align="center">Loading...</Typography>
          </Grid>
  </Grid>
    )
}
}




export default withStyles(styles)(CircularLoading);