import React, { Component } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider, withStyles, makeStyles } from '@material-ui/core/styles';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Today } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  root: {
    paddingTop: '20px',
    paddingBottom: '30px',
    flexGrow: 1,
  },

  areaChart: {
    backgroundColor: ' #E3E3E3',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 18,
    verticalAlign: 'text-bottom',
  },
});






//End Custom tooltip


class DailyStats extends Component {


  render() {
    const { classes, listDailyAffluance, dateNow, t } = this.props;
    return (

      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <Typography color="primary" variant='h6'>
            <strong>{t(`description.DailyAffluence`)}</strong>
          </Typography>
        </Grid>
        <Grid item xs={6} alignContent='flex-end'>

          <Typography variant='body1' align="right" display='block'>
            <Today className={classes.icon} />{dateNow}
          </Typography>
        </Grid>

        <ResponsiveContainer height={300} >
          <AreaChart
            className={classes.areaChart}
            data={listDailyAffluance}
            margin={{
              top: 10, right: 30, left: 0, bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 0" />
            <XAxis dataKey="hours" interval={50} dy={5} />
            <YAxis />
            <Tooltip />

            <Area dataKey="people" stroke="#CB2953" fill="#CB2953" fillOpacity="1" />
            <Area type="monotone" dataKey="peopleMax" stroke="#FF0049" fill="#EEA831" fillOpacity="0" />
            <Area type="monotone" dataKey="securityLimit" stroke="#EEA831" fill="#CB2953" fillOpacity="0" />
          </AreaChart>
        </ResponsiveContainer>
      </Grid>

    )
  }
}




export default withStyles(styles)(withTranslation()(DailyStats));