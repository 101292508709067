import React, { Component } from "react";
import { Button, Typography, Container } from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Translation, withTranslation } from "react-i18next";
import BackArrowNavigation from "../components/header/BackArrowNavigation";
import Footer from "../components/footer/Footer";
import AlarmList from "../components/zoneComponents/AlarmList";
import DownloadPdfButton from "../components/ViewPdf/DownloadPdfButton";
import PdfInformation from "../components/ViewPdf/PdfInformation";
import StatsPdf from "../components/ViewPdf/StatsPdf";
import CircularLoading from "../components/Progress/CircularLoading";
import * as CommonApi from "../utils/common-api";
import * as CommonStorage from "../utils/common-storage";
import { withRouter } from "react-router";
import moment from "moment";
import queryString from "query-string";

const styles = (theme) => ({
  root: {
    minHeight: "calc(100vh - 124px)",
    backgroundColor: "#fff",
    padding: 0,
    paddingTop: "60px",
    flexGrow: 1,
  },

  buttonContainer: {
    paddingTop: "90px",
    paddingBottom: "30px",
    backgroundColor: "#eeeeee",
    textAlign: "center",
  },
  grey: {
    backgroundColor: "#eeeeee",
  },
  white: {
    marginBottom: 20,
    padding: 20,
    backgroundColor: "#fff",
  },
  buttonDonwnload: {
    padding: '6px 20px',
    cursor: "default",
    marginTop: "30px",
    borderRadius: "50px",
    color: "#fff",
    pointerEvents: 'none',
    backgroundColor: '#969696',
  },
});

class PdfPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "page",
      zone_name: "",
      place_name: "",
      place_description: "",
      list_daily_affluance: [],
      list_daily_alarm: [],
      date_now: moment().format("DD/MM/YYYY"),
      list_alarm: [],
      image_chart: "",
      show_download: "false",
    };
    this.myRef = React.createRef();
    this.imageChartLoaded = this.imageChartLoaded.bind(this);
  }

  async componentDidMount() {
    const { history } = this.props;
    try {
      this.setState({ screen: "loading", list_reports: [], zone_name: "" });
      var current_zone = CommonStorage.getCurrentZone();
      var current_place = CommonStorage.getCurrentPlace();

      this.setState({ place_name: current_place.name });
      this.setState({ place_description: current_place.description });
      this.setState({ zone_name: current_zone.name });

      let params = queryString.parse(this.props.location.search);
      if (params.date !== "") {
        this.setState({
          date_now: moment(params.date).format("DD/MM/YYYY"),
        });
      } else {
        history.push("/");
      }

      //
      //GET FLIDITY
      //
      const requestFluidityLive = {
        ...CommonApi.getRequestOptions(),
        method: "GET",
      };

      var dateFrom = moment(params.date).toDate();
      dateFrom.setHours(0);
      dateFrom.setMinutes(0);
      dateFrom.setSeconds(0);
      dateFrom.setMilliseconds(0);

      var dateTo = moment(params.date).toDate();
      dateTo.setHours(24);
      dateTo.setMinutes(0);
      dateTo.setSeconds(0);
      dateTo.setMilliseconds(0);

      var responseFluidityLive = await fetch(
        CommonApi.getBaseUrlApi() +
        "/fluidity-live?date_from=" +
        dateFrom.toISOString() +
        "&date_to=" +
        dateTo.toISOString() +
        "&region_id=" +
        current_zone.id,
        requestFluidityLive
      );
      const dataFluidityLive = await responseFluidityLive.json();
      if (!responseFluidityLive.ok) {
        if (responseFluidityLive.status === 403) {
          console.log("A problem has occurred");
          history.push("/");
        } else if (responseFluidityLive.status === 401) {
          //Redirect to login
          history.push("/login");
        } else {
          //Error
          console.log("A problem has occurred");
          history.push("/");
        }
      } else {
        //OK
        if (dataFluidityLive !== null) {
          const dataListFluidity = [];
          dataFluidityLive.items.map((row) =>
            dataListFluidity.push({
              hours: new moment(row.date).format("HH:mm"),
              people: row.count,
              peopleMax: dataFluidityLive.max_capacity,
              securityLimit: dataFluidityLive.alert_capacity,
            })
          );

          this.setState({ list_daily_affluance: dataListFluidity });
        }
      }

      //
      //GET ALERT HISTORY
      //
      const requestAlertHistory = {
        ...CommonApi.getRequestOptions(),
        method: "GET",
      };

      var responseAlertHistory = await fetch(
        CommonApi.getBaseUrlApi() +
        "/alert-history?date=" +
        dateTo.toISOString() +
        "&region_id=" +
        current_zone.id,
        requestAlertHistory
      );
      const dataAlertHistory = await responseAlertHistory.json();
      if (!responseAlertHistory.ok) {
        if (responseAlertHistory.status === 403) {
          console.log("A problem has occurred");
          history.push("/");
        } else if (responseAlertHistory.status === 401) {
          //Redirect to login
          history.push("/login");
        } else {
          //Error
          console.log("A problem has occurred");
          history.push("/");
        }
      } else {
        //OK
        if (dataAlertHistory !== null) {
          const dataListAlertHistory = [];
          const dataListAlertPdfHistory = [];

          //Data List
          dataAlertHistory.map((row) =>
            dataListAlertHistory.push({
              time: new moment(row.date).format("HH:mm"),
              typealarm:
                row.kind === "MAX" ? "SecurityExceeded" : "SecurityReached",
              people: row.capacity_description,
              color: row.kind === "MAX" ? "primary" : "error",
            })
          );

          //Data PDF
          dataAlertHistory.map((row) =>
            dataListAlertPdfHistory.push({
              time: new moment(row.date).format("HH:mm"),
              typealarm:
                row.kind === "MAX" ? "SecurityExceeded" : "SecurityReached",
              people: row.capacity_description,
              color: row.kind === "MAX" ? "#CB2953" : "#EDA831",
            })
          );

          this.setState({
            list_daily_alarm: dataListAlertHistory,
            list_alarm: dataListAlertPdfHistory,
          });
        }
      }

      //SHOW PAGE
      this.setState({ screen: "page" });

      // await sleep(7000)
      // this.myRef.current.getImage()
    } catch (error) {
      console.log("A problem has occurred");
      history.push("/");
    }
  }

  imageChartLoaded(value) {
    this.setState({ image_chart: value, show_download: "true" });
  }

  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.grey}>
        <BackArrowNavigation zoneName={this.state.zone_name} />
        {this.state.screen === "loading" && (
          <div className={classes.root}>
            <CircularLoading />
          </div>
        )}
        {this.state.screen === "page" && (
          <div>
            {this.state.show_download === "true" && (
              <DownloadPdfButton
                imageChart={this.state.image_chart}
                listAlarm={this.state.list_alarm}
                dateNow={this.state.date_now}
                zoneName={this.state.zone_name}
                placeName={this.state.place_name}
                placeDescription={this.state.place_description}
              />
            )}
            {this.state.show_download === "false" && (
              <Container maxWidth="false" className={classes.buttonContainer}>
                <Typography variant="body1" align="center">
                  <strong>{t(`description.DownloadThePdf`)}</strong>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.buttonDonwnload}
                >
                  Loading...
                </Button>
              </Container>
            )}
            <Container maxWidth="lg" className={classes.white}>
              <Container maxWidth="md" className={classes.root}>
                <PdfInformation
                  zoneName={this.state.zone_name}
                  placeName={this.state.place_name}
                  placeDescription={this.state.place_description}
                />
                <StatsPdf
                  ref={this.myRef}
                  imageChartLoaded={this.imageChartLoaded}
                  listDailyAffluance={this.state.list_daily_affluance}
                  dateNow={this.state.date_now}
                />
                <AlarmList
                  listDailyAlarm={this.state.list_daily_alarm}
                  dateNow={this.state.date_now}
                />
              </Container>
            </Container>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(PdfPage)));
